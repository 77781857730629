import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// Firebase configuration - replace with your actual config
const firebaseConfig = {
  apiKey: 'AIzaSyDkEC1CTFajgFCScpWKsrY_Q3z20IbVhik',
  //   authDomain: 'YOUR_AUTH_DOMAIN',
  projectId: 'deehiy-chat-app',
  //   storageBucket: 'YOUR_STORAGE_BUCKET',
  appId: '1:529334209928:web:260f7105b2ea387ed57e3d',
  messagingSenderId: '529334209928',
  vapidKey:
    'BK57V6Wo-u2v5svU5tpr4z2YBYDow4me5bsDtJcs0F7WDqFpr69nLOJQmMICOMNmMEBSRmgLG3n-MAmRTlQhefs', // VAPID Key for Web Push Notifications
};

// Initialize Firebase app and messaging instance
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

/**
 * Request and generate the FCM token for the device.
 * @returns {Promise<string>} - The generated FCM token.
 */
export const generateToken = async () => {
  try {
    const token = await getToken(messaging, {
      vapidKey: firebaseConfig.vapidKey,
    });
    if (token) {
      console.log('FCM token generated:', token);
      return token;
    } else {
      console.warn('No FCM token retrieved. User may not have granted permissions.');
      return null;
    }
  } catch (error) {
    console.error('Error generating FCM token:', error);
    throw error;
  }
};

/**
 * Refresh the FCM token when it expires.
 * @param {function} onRefreshCallback - Callback to handle the refreshed token.
 */
// export const handleTokenRefresh = (onRefreshCallback: any) => {
//   onTokenRefresh(async () => {
//     try {
//       const newToken = await generateToken();
//       if (newToken) {
//         console.log('Token refreshed:', newToken);
//         if (onRefreshCallback) {
//           onRefreshCallback(newToken);
//         }
//       }
//     } catch (error) {
//       console.error('Error refreshing FCM token:', error);
//     }
//   });
// };

/**
 * Listen for foreground messages.
 * @param {function} callback - Callback to handle incoming messages.
 */
export const listenForMessages = (callback: any) => {
  onMessage(messaging, (payload: any) => {
    console.log('Message received in foreground:', payload);
    if (callback) {
      callback(payload);
    }
  });
};
