import React, { createContext, useEffect, useReducer, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../services/auth/authService';
import {
  register,
  resetPassword,
  resetUpdatePassword,
  update,
  getUserById,
} from '../services/auth/userService';
import { userReducer } from '../reducers/userReducer';
import { User } from '../types/types';
import { initialContext } from '../initialStates/initialStates';
import jwt_decode from 'jwt-decode';
import { RESET_PASSWORD, SET_CURRENT_USER, LOGOUT, GET_ERRORS } from '../actions/actions';
import { useBusiness } from './business';
import { useInternationalization } from './internationalization';
// import i18n from '../i18n';
import socket from '../utils/socket';

import setAuthToken from '../utils/setAuthToken';
import httpService from '../services/httpService';
import { baseUrl } from '../utils/helper';
import { generateToken } from '../utils/firebase';

const AuthContext = createContext(initialContext);

export const AuthProvider = ({ children }: any) => {
  const { currentBusiness } = useBusiness();
  const { locale, setLocale } = useInternationalization();

  const [{ message, currentUser }, dispatch] = useReducer(userReducer, initialContext);

  const [isLoading, setIsLoading] = useState(true);

  function changeLocale(l: string) {
    if (locale !== l) {
      // i18n.changeLanguage(l);
      setLocale(l); // might be unnecessary
    }
  }

  useEffect(() => {
    const storagedUser = localStorage.getItem('@App:user');
    const storagedToken = localStorage.getItem('@App:token');

    if (storagedToken && storagedUser) {
      // api.defaults.headers.Authorization = `Bearer ${storagedToken}`;
      dispatch({ type: SET_CURRENT_USER, payload: jwt_decode(storagedUser) });
      setAuthToken(storagedToken);
    }
  }, []);

  const navigate = useNavigate();

  const loginWithSocialApi = async (
    given_name: string,
    family_name: string,
    email: string,
    picture: string,
    accountType: string
  ) => {
    try {
      // Make API call to your backend to handle Google login
      const socialAuthApi = await httpService.post(`${baseUrl}/api/users/social-signup`, {
        firstName: given_name,
        lastName: family_name,
        email,
        image: picture,
        accountType,
      });

      // Extract JWT token from the response
      const jwt = socialAuthApi.data;

      // Connect socket and set auth token
      socket.connect();
      setAuthToken(jwt);

      // Decode JWT to get user details
      const userDetails: any = jwt_decode(jwt);

      // Update user state
      dispatch({ type: SET_CURRENT_USER, payload: userDetails });

      const userLanguage = userDetails.language;
      changeLocale(userLanguage);
      // localStorage.setItem('language', userLanguage);

      // Store token and user data in local storage
      localStorage.setItem('@App:user', jwt);
      localStorage.setItem('@App:token', jwt);

      return 'success';
    } catch (error) {
      // Handle error
      console.error('Google login error:', error);
      return 'error';
    }
  };

  // login
  const loginUser = async (email: string, password: string) => {
    const deviceToken = (await generateToken()) || '';
    const deviceType = /Mobile|Android|iOS/.test(navigator.userAgent) ? 'mobile' : 'web';

    return login(email, password, deviceToken, deviceType)
      .then((res: any) => {
        const jwt = res.data;
        socket.connect();

        setAuthToken(jwt);

        const userDetails: any = jwt_decode(jwt);
        dispatch({ type: SET_CURRENT_USER, payload: userDetails });

        const userLanguage = userDetails.language;
        changeLocale(userLanguage);
        // localStorage.setItem('language', userLanguage);

        // localStorage.setItem('@App:user', JSON.stringify(res.data.user));
        localStorage.setItem('@App:user', jwt);
        localStorage.setItem('@App:token', jwt);

        return 'success';
      })
      .catch((err) => {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
        return err.response.data;
      });
  };

  const LoginWithToken = async (authToken: string) => {
    try {
      const jwt = authToken;
      socket.connect();

      setAuthToken(jwt);

      const userDetails: any = jwt_decode(jwt);
      dispatch({ type: SET_CURRENT_USER, payload: userDetails });

      const userLanguage = userDetails.language;
      changeLocale(userLanguage);
      // localStorage.setItem('language', userLanguage);

      // localStorage.setItem('@App:user', JSON.stringify(res.data.user));
      localStorage.setItem('@App:user', jwt);
      localStorage.setItem('@App:token', jwt);

      return 'success';
    } catch (err: any) {
      dispatch({ type: GET_ERRORS, payload: err.response.data });
      return err.response.data;
    }
  };

  // Register user
  const registerUser = async (user: User) => {
    return register(user)
      .then(() => {
        return 'success';
      })
      .catch((err) => {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
        return err.response.data;
      });
  };

  // Reset password user
  const resetPasswordUser = async (email: string) => {
    return resetPassword(email)
      .then((res: any) => {
        dispatch({ type: RESET_PASSWORD, payload: res.data });
        return 'success';
      })
      .catch((err) => {
        return err.response.data;
      });
  };

  // Update user password
  const resetUpdatePasswordUser = async (userId: number, token: string, password: string) => {
    return resetUpdatePassword(userId, token, password)
      .then((data: any) => {
        console.log(data);
        return 'success';
      })
      .catch((err: any) => {
        console.log(err);
        if ((err.response && err.response.status === 400) || err.response.status === 404) {
          return err.response.data;
        }
      });
  };

  // Logout user
  const logoutUser = () => {
    const appToken = localStorage.getItem('@App:token');
    socket.emit('logout', { userId: currentUser.id, token: appToken }, (logoutAck: any) => {
      console.log('logoutAck', logoutAck);
    });

    // socket.emit('disconnect_user', { userId: currentUser.id }, (disconnectUserAck: any) => {
    //   console.log('disconnectUserAck', disconnectUserAck);
    //   socket.disconnect();
    // });
    localStorage.removeItem('@App:user');
    localStorage.removeItem('@App:token');

    dispatch({ type: LOGOUT, payload: {} });
    setAuthToken(false);
    navigate('/');
  };

  /**
   * Updates the user data in the database and local storage.
   * @param {any} params - The updated params.
   * @returns {Promise<string | undefined>} - A Promise that resolves with "success" if the update was successful,
   * or rejects with an error message string if the update failed.
   */
  const updateUser = async (params: any): Promise<string | undefined> => {
    try {
      const updatedUser = await update({ ...params, id: currentUser?.id });
      console.log('UPDATED USER', updatedUser);
      const authToken = updatedUser.data.token;
      localStorage.setItem('@App:user', authToken);
      localStorage.setItem('@App:token', authToken);

      dispatch({ type: SET_CURRENT_USER, payload: { ...currentUser, ...params } });
      // dispatch({ type: SET_CURRENT_USER, payload: updatedUser.data });
      return 'success';
    } catch (err: any) {
      dispatch({ type: GET_ERRORS, payload: err.response.data });
      return err.response.data;
    }
  };

  /**
   * Fetches a user from the server by their ID.
   *
   * @param id - The ID of the user to fetch.
   *
   * @returns A Promise that resolves to the fetched User object.
   * @throws An error if the request fails.
   */
  const fetchUserById = async (id: number): Promise<User> => {
    try {
      const res = await getUserById(id);
      const foundUser = { ...res.data };

      return foundUser;
    } catch (err) {
      console.log(err);
      throw new Error('Failed to fetch user');
    }
  };

  const setCurrentUserAsBusinessAdmin = (adminId: number) => {
    if (currentBusiness && currentUser.id === adminId) {
      dispatch({ type: SET_CURRENT_USER, payload: { ...currentUser, roleId: 1 } });
    }
  };

  const value = {
    currentUser,
    isLoading,
    message,
    loginWithSocialApi,
    loginUser,
    LoginWithToken,
    registerUser,
    logoutUser,
    fetchUserById,
    resetPasswordUser,
    resetUpdatePasswordUser,
    updateUser,
    setCurrentUserAsBusinessAdmin,
  };

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}
